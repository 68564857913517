/*
 * @Author: shortfeng shortfeng@163.com
 * @Date: 2023-06-29 15:39:08
 * @LastEditors: shortfeng shortfeng@163.com
 * @LastEditTime: 2023-06-29 17:38:04
 * @FilePath: /zimei-home/website-sister/src/components/Head/index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';

import "./index.scss"


export default (props) => {
    const [selectactive, setSelectactive] = useState('');

    const headArr = [
        {
            "id": 0,
            "text": "所有商品",
            "url": '//sistercloud.cn/store/#/shop'
        },
        {
            "id": 1,
            "text": "每日信息",
            "url": '//sistercloud.cn/store/#/info/list'
        },
        {
            "id": 2,
            "text": "商家店铺",
            "url": '//sistercloud.cn/store/#/stores/list'
        }
    ]

    const jump = (item, index) => {
        setSelectactive(index);
        // console.log(item, index, "itemitemitem")
        // console.log(item.url)
        window.open(item.url)
        // var offsetTop = document.getElementById(item.id).offsetTop;

        // console.log('offsetTop', offsetTop);
        // window.scrollTo({
        //     top: offsetTop,
        //     behavior: "smooth",
        // });
    }

    return <div className="top-header">
        <div className='container right' id="0">
            <div className='menu'>
                <ul>
                    {
                        headArr.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    onClick={jump.bind(null, item, index)}
                                >
                                    {item.text}
                                </li>
                            );
                        })}

                </ul>
            </div>
        </div>

    </div>
}