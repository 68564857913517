import React, { useEffect, useState } from 'react';
import Header from 'components/Head_records';
import Footer from 'components/Footer_records';

import { Link } from 'react-router-dom';
import IndexAPI from 'api/Index';

import "./index.scss"

export default (props) => {
    const [list, setList] = useState<any>([])

    useEffect(() => {
        IndexAPI.getUpdates({
            limit: 50,
            pageNum: 1,
            systemType: 1,
            serviceType: "zimei",
            numState: 5
        }).then(res => {
            res.data.list.forEach(item => {
                var timeArr = item.releaseTime.split(" ")
                if (timeArr[0]) {
                    item.time1 = timeArr[0].split("-")[0] + '-' + timeArr[0].split("-")[1]
                    item.time2 = timeArr[0].split("-")[2]
                }
                var string = item.updateContent;
                //替换所有的换行符
                string = string.replace(/\r\n/g, "<br>")
                string = string.replace(/\n/g, "<br>");

                //替换所有的空格（中文空格、英文空格都会被替换）
                string = string.replace(/\s/g, " ");

                //输出转换后的字符串
                item.newUpdateContent = string

            })
            setList(res.data.list)
        })



    }, [])


    return (
        <>
            <Header />
            <div>
                <div className="main main-log">
                    <div className="date-ct clear">
                        <div className="date-line"></div>

                        <ul>
                            {
                                list.map((item, index) => {
                                    return (
                                        <li className={index % 2 === 0 ? "date-log-item date-fr" : "date-log-item date-fl"} key={item.id} >
                                            <span className="arr"></span>
                                            <span className="dot"></span>
                                            <div className="date-log-item-in clear">
                                                <h5>{item.versionNumber}更新说明</h5>
                                                <p className="date-icon">
                                                    {
                                                        item.time1
                                                    }
                                                    <span>
                                                        {
                                                            item.time2
                                                        }
                                                    </span>
                                                </p>
                                                <div className='content' dangerouslySetInnerHTML={{ __html: item.newUpdateContent }}>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }


                        </ul>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    )


}