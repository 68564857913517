import React, { useEffect, useState } from 'react';

import "./index.scss"


export default (props) => {
    const [selectactive, setSelectactive] = useState(0);


    return (
        <div className='bottom'>
            <div className='container'>
                <div className='left'>
                    <img src={require("../../assets/img/logo_white.png")} alt="" />
                    <p>
                        杭州采美企业管理有限公司 <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2023004641 ">浙ICP备2023004641号-1</a>
                    </p>
                </div>
                <div className='right'>
                    <ul>
                        <li>首页</li>
                        <li>热销爆款</li>
                        <li>应用介绍</li>
                        <li>联系我们</li>
                        <li>加盟我们</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}