import React, { useEffect, useState } from 'react';
import TopHead from 'components/Top_head';
import Header from 'components/Head';
import Footer from 'components/Footer';

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Select, Input, message } from "antd"
import { Link } from 'react-router-dom';
import { Map, Marker, NavigationControl, InfoWindow } from 'react-bmap'
import IndexAPI from 'api/Index';

import "./index.scss"

declare global {
    interface Window {
        gsap: any
        ScrollTrigger: any
    }
}

export default (props) => {
    const [swiperList, setSwiperList] = useState<any>([])
    const [leftModuleList, setLeftModuleList] = useState<any>([])
    const [rightModuleList, setRightModuleList] = useState<any>([])
    const [activeType, setActiveType] = useState<any>("首页")
    const [activeBg, setActiveBg] = useState<string>("")
    const [name, setName] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [textareaVal, setTextareaVal] = useState<string>("")
    const [versionData, setVersionData] = useState<any>({
        newVersion: {}
    })
    const [joinWay, setJoinWay] = useState<string>("1")
    SwiperCore.use([Autoplay, Pagination, Navigation])
    const { Option } = Select


    const shopList = [
        {
            "img":  require('../../assets/img/shop_one.png'),
            "text": "地址：安徽省合肥市包河区徽州大道838号漫乐城 购物中心2楼布寻裳女装"
        },
        {
            "img": require('../../assets/img/shop_two.png'),
            "text": "地址：浙江省杭州市西湖区城西银泰C座-2楼"
        },
        {
            "img": require('../../assets/img/shop_three.png'),
            "text": "地址：安徽省广德市万桂山南路万象商业街A-108 号布寻裳女装"
        }
    ]

    useEffect(() => {

        IndexAPI.getVersion({
            versionNumber: "v1.0.0",
            systemType: 1,
            serviceType: "zimei"
        }).then(res => {
            setVersionData(res.data)
        })

        IndexAPI.getHotData({
            inSeq: 1,
            numState: 1
        }).then(res => {
            setSwiperList(res.data.list || [])
        })

        IndexAPI.getAppContext({
        }).then(res => {
            var arr1: any = [], arr2: any = [];
            res.data?.list?.forEach((item, index) => {
                switch (index) {
                    case 0:
                        item.activeIcon = require("../../assets/img/icon_home_red2.png")
                        break;
                    case 1:
                        item.activeIcon = require("../../assets/img/icon_pool_red2.png")
                        break;
                    case 2:
                        item.activeIcon = require("../../assets/img/icon_task_red2.png")
                        break;
                    case 3:
                        item.activeIcon = require("../../assets/img/icon_user_red2.png")
                        break;
                    case 4:
                        item.activeIcon = require("../../assets/img/icon_task_red2.png")
                        break;
                    default:
                        item.activeIcon = require("../../assets/img/icon_home_red.png")
                        break;
                }

                if (index % 2 === 0) {
                    arr1.push(item)
                } else {
                    arr2.push(item)
                }
            })
            setActiveBg(arr1[0].picture)
            arr1[0].active = true;
            setLeftModuleList(arr1)
            setRightModuleList(arr2)
        })
    }, [])

    useEffect(() => {
        console.log(window.gsap, "gsapgsap");
        if (window.gsap) {
            const gsap = window.gsap
            gsap.registerPlugin(window.ScrollTrigger);

            gsap.set(".banner .phone-img", {
                x: -600,
                opacity: 0
            })
            window.ScrollTrigger.batch(".banner .phone-img", {
                onEnter: batch => gsap.to(batch, { x: 0, opacity: 1 }),
                onLeave: batch => gsap.set(batch, { x: -600, opacity: 0 }),
                onEnterBack: batch => gsap.to(batch, { x: 0, opacity: 1 }),
                end: "bottom top"
            })

            gsap.fromTo(".banner .phone-img", {
                opacity: 0,
                duration: 1,
            }, {
                opacity: 1,
                duration: 1,
            })
            gsap.to(".banner .phone-img", {
                duration: 1.2,
                y: -20,
                repeat: -1,
                yoyo: true,
            });

            gsap.set(".banner .circle-img", {
                x: -600,
                opacity: 0
            })

            gsap.to(".banner .circle-img", {
                duration: 8,
                rotation: 360,
                repeat: -1,
                ease: "none"
            });

            window.ScrollTrigger.batch(".banner .circle-img", {
                onEnter: batch => gsap.to(batch, { x: 0, opacity: 1 }),
                onLeave: batch => gsap.set(batch, { x: -600, opacity: 0 }),
                onEnterBack: batch => gsap.to(batch, { x: 0, opacity: 1 }),
                end: "bottom top"
            })


            gsap.set(".banner .banner-content", {
                y: -100,
                opacity: 0
            })
            window.ScrollTrigger.batch(".banner .banner-content", {
                onEnter: batch => gsap.to(batch, { y: 0, opacity: 1 }),
                onLeave: batch => gsap.set(batch, { y: -100, opacity: 0 }),
                onEnterBack: batch => gsap.to(batch, { y: 0, opacity: 1 }),
                start: "100px bottom",
            })


            gsap.from(".introduce .left, .introduce .right", {
                scrollTrigger: {
                    trigger: ".introduce .left",
                    start: "500px bottom"
                },
                duration: 1.5,
                y: -400,
                repeat: 0,
                ease: "expo"
            })

            // gsap.from(".contract .item", {
            //     scrollTrigger: {
            //         trigger: ".contract .img-title",
            //         start: "500px bottom"
            //     },
            //     duration: 1.5,
            //     y: 600,
            //     repeat: 0,
            //     // yoyo: true,
            //     ease: "expo"
            // })

        }
    }, [])
    const chooseModule = item => {
        setActiveType(item.name)
        setActiveBg(item.picture)
    }

    const handleSelectChange = (value: string) => {
        setJoinWay(value)

    };
    const handleNameChange = (item) => {
        setName(item.target.value)
    };
    const handlePhoneChange = (item) => {
        setPhone(item.target.value);
    };
    const handleTextareaChange = (item) => {
        setTextareaVal(item.target.value);
    };

    const submit = () => {
        if (!name || !phone) {
            message.warn("请填写名字和电话")
            return;
        }
        IndexAPI.addJoin({
            joinWay,
            name,
            phone,
            remarks: textareaVal
        }).then(res => {
            message.success("提交成功")
        })
    }

    return (
        <>
            <TopHead />
            <Header />
            <div className='main-content'>
                <div className='banner container' >
                    <div className='phone'>
                        <img className='phone-img' src={require("../../assets/img/phone2.png")} alt="" />
                        <img className='circle-img' src={require("../../assets/img/rotate-word2.png")} alt="" />
                    </div>

                    <div className='banner-content'>
                        <div className='title'>
                            <em>云品会选</em>专注于<br />
                            打造新零售，新时尚
                        </div>
                        <div className='info'>
                            以服装为媒介，打造整个城市的本地化线上线下服务
                            <div>
                                <span>
                                    版本: {versionData.newVersion.versionNumber}
                                </span>
                                <em>｜</em>
                                <span>
                                    发布时间: {versionData.newVersion.releaseTime}
                                </span>
                                <em>｜</em>
                                <Link to="/update-records">
                                    <span className='green' style={{color: '#F15247'}}>更新记录</span>
                                </Link>
                            </div>

                        </div>
                        <div className='download-wrap'>
                            <div className='download-zimei'>
                                下载云品会选APP
                                <img src={require("../../assets/img/icon_right.png")} alt="" />
                            </div>
                            <div className='qr-code'>
                                <img src={require("../../assets/img/qr_code.png")} alt="" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="word-wrap line2">
                    <div className="cont">
                        <p className="txt">
                            <img src={require("../../assets/img/04.png")} alt="" />
                        </p>
                        <p className="txt">
                            <img src={require("../../assets/img/04.png")} alt="" />
                        </p>
                    </div>
                </div>
                <div className="word-wrap">
                    <div className="cont">
                        <p className="txt">
                            <img src={require("../../assets/img/032.png")} alt="" />
                        </p>
                        <p className="txt">
                            <img src={require("../../assets/img/032.png")} alt="" />
                        </p>
                    </div>
                </div>

                {/* <div className='line'>
                    <img className='word1' src={require("../../assets/img/word1.png")} alt="" />
                    <img className='word2' src={require("../../assets/img/word2.png")} alt="" />
                </div> */}
                <div className='hot container' id="1">
                    <h2 className='sub-title'>
                        <span>云品会选</span>
                        爆款
                    </h2>
                    <div className='tip'>
                        <p>让“好看”变日常，每一身穿搭，云品会选都给你最好的惊喜。</p>
                        <div className='prev-next'>
                            <img className='swiper-button-prev' src={require("../../assets/img/icon_left2.png")} alt="" />
                            <img className='swiper-button-next' src={require("../../assets/img/icon_right_red2.png")} alt="" />
                        </div>
                    </div>
                    <div className='swiper-img'>

                        <Swiper
                            slidesPerView={4}
                            spaceBetween={24}

                            loop={false}
                            navigation={
                                {
                                    enabled: true,
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }
                            }
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false
                            }}

                            onSwiper={swiper => console.log(swiper)}>

                            {
                                swiperList.map(item => {
                                    return (
                                        <SwiperSlide className="sub-slide" key={item.id}>
                                            <img
                                                src={item.goodsImg}
                                                alt=""
                                            />
                                            <p>{item.goodsName}</p>
                                            <span>
                                                ¥{item.goodsPrice}
                                            </span>
                                        </SwiperSlide>
                                    )
                                })
                            }

                        </Swiper>

                    </div>
                </div>
                <div className='introduce' id="2">
                    <div className='container'>
                        <div className='left'>
                            <div className='img-title'>
                                <img src={require("../../assets/img/yinhao.png")} alt="" />
                                <span>应用介绍</span>
                            </div>
                            {
                                leftModuleList.map(item => {
                                    return <div onClick={() => { chooseModule(item) }}
                                        className={activeType === item.name ? "introduce-item active" : "introduce-item"} key={item.id}>

                                        <img src={activeType === item.name ? item.activeIcon : item.icon} alt="" />
                                        <span>{item.name}</span>
                                        <p>{item.content}</p>
                                    </div>
                                })
                            }
                        </div>

                        <img className='phone' src={activeBg} alt="" />

                        <div className='right'>
                            {
                                rightModuleList.map(item => {
                                    return <div className={activeType === item.name ? "introduce-item active" : "introduce-item"} key={item.id} onClick={() => { chooseModule(item) }}>
                                        <img src={activeType === item.name ? item.activeIcon : item.icon} alt="" />
                                        <span>{item.name}</span>
                                        <p>{item.content}</p>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className='brand'  id="3">
                    <div className='inner-warp'>
                        <div className='img-title'>
                            <img src={require("../../assets/img/yinhao_red.png")} alt="" />
                            <span>
                                服装<em>品牌</em>
                            </span>
                        </div>
                        <div className='tips'>
                            <p>布寻裳是一个时尚女装品牌，致力于为三四线城市的年轻女性群体打造高品质、紧跟时尚潮流且价格亲民的服装。</p>
                            <p>布寻裳以简约、自信和优质为品牌理念，引领女性为自己穿出最好的形象。布寻裳强调简约时尚，为女性提供更舒适、优雅的选择。同时，布寻裳通过设计出多样化的款 式，希望每一位顾客都能找到适合自己的风格！</p>
                        </div>
                        <div className='menu'>
                            <ul>
                                {
                                    shopList.map((item, index) => {
                                        return (
                                            <li className={index == 1 ? "cent" : ""} key={index}>
                                                <img src={item.img} alt="" />
                                                <span>{item.text}</span>
                                            </li>
                                        );
                                    })}

                            </ul>
                        </div>
                    </div>
                </div>
                <div className='contract container' id="4">
                    <div className='left'>
                        <div className='img-title'>
                            <img src={require("../../assets/img/yinhao_red.png")} alt="" />
                            <span>
                                联系<em>云品会选</em>
                            </span>
                        </div>

                        <div className='item'>
                            <img src={require("../../assets/img/icon_number2.png")} alt="" />
                            <p>0571-28999994</p>
                        </div>
                        <div className='item wx-item'>
                            <div >
                                <img src={require("../../assets/img/icon_wachat2.png")} alt="" />
                                <p>云品会选官方微信客服</p>
                            </div>
                            <div className='wx-code'>
                                <img src={require("../../assets/img/wx_qr.png")} alt="" />
                            </div>
                        </div>
                        <div className='item'>
                            <img src={require("../../assets/img/icon_base2.png")} alt="" />
                            <p>浙江省杭州市拱墅区祥园路88号k幢7层</p>
                        </div>
                        <div className='item cooperate'>
                            <img src={require("../../assets/img/hk_logo.png")} alt="" />
                            <img src={require("../../assets/img/hw_logo.png")} alt="" />
                        </div>
                    </div>
                    
                    <div className='right'>
                        <p>云品会选APP由杭州采美企业管理有限公司设计研发和推出，杭州采美企业管理有限公司，是一家领先的互联网科技及技术应用服务提供商，具备完善的项目管理和运营服务机制，倡导“专业、高效、创新”的精神。</p>
                        <p>采美注重商业本质、技术支持和配套功能建设，依托积累的市场渠道、商务资源及媒体关系，用数字科技连接消费金融和实体产业，助力产业提升互联网化、数字化、智能化水平，推动实体经济的创新发展，创造社会价值。</p>
                        <div style={{ height: "500px" }}>
                            <Map center={{ lng: 120.12469, lat: 30.34221 }} zoom="16" style={{ height: "500px" }}>
                                <Marker position={{ lng: 120.12469, lat: 30.34221 }} title="中国（杭州）智慧信息产业园"></Marker>
                                {/* <NavigationControl />
                    <InfoWindow position={{ lng: 120.12469, lat: 30.34221 }} text="智慧信息产业园" title="中国（杭州）" /> */}
                            </Map>
                        </div>

                    </div>
                </div>
                <div className='join' id="5">
                    <div className='container'>
                        <h2 className='sub-title'>
                            加盟<span>云品会选</span>
                        </h2>

                        <div className='forms'>
                            <div className='line1'>
                                <Select className="collection" onChange={handleSelectChange} placeholder="加盟云品会选合伙人">
                                    <Option value="1">加盟云品会选合伙人</Option>
                                    <Option value="2">加盟云品会选店铺</Option>
                                </Select>
                                <Input placeholder='您的称呼' onChange={handleNameChange} />
                                <Input placeholder='您的联系电话' onChange={handlePhoneChange} />
                            </div>
                            <div className='line2'>
                                <Input.TextArea onChange={handleTextareaChange} placeholder='您有开服装门店、服装导购经验都可以写在这里～' />
                            </div>
                        </div>
                        <div className='submit' onClick={submit}>
                            提交
                            <img src={require("../../assets/img/icon_right.png")} alt="" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )


}