/*
 * @Author: shortfeng shortfeng@163.com
 * @Date: 2023-06-29 15:39:08
 * @LastEditors: shortfeng shortfeng@163.com
 * @LastEditTime: 2023-06-29 17:38:04
 * @FilePath: /zimei-home/website-sister/src/components/Head/index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useEffect, useState } from 'react';

import "./index.scss"


export default (props) => {
    const [selectactive, setSelectactive] = useState(0);

    const headArr = [
        {
            "id": 0,
            "text": "首页"
        },
        {
            "id": 1,
            "text": "热销爆款"
        },
        {
            "id": 2,
            "text": "应用介绍"
        },
        {
            "id": 3,
            "text": "服装品牌"
        },
        {
            "id": 4,
            "text": "联系我们"
        },
        {
            "id": 5,
            "text": "加盟我们"
        },
    ]

    const jump = (item, index) => {
        setSelectactive(index);
        console.log(item, index, "itemitemitem")
        var offsetTop = document.getElementById(item.id).offsetTop;

        console.log('offsetTop', offsetTop);
        window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
        });
    }

    return <div className="header">
        <div className='container' id="0">
            <div className='logo' onClick={() => { window.location.href = "/" }}>
                <img src={require("../../assets/img/logo3.png")} alt="" />
            </div>
            <div className='menu'>
                <ul>
                    {
                        headArr.map((item, index) => {
                            return (
                                <li
                                    className={
                                        item.id === selectactive
                                            ? "active"
                                            : ""
                                    }
                                    key={index}
                                    onClick={jump.bind(null, item, index)}
                                >
                                    {item.text}
                                </li>
                            );
                        })}

                </ul>
            </div>
        </div>

    </div>
}